<template>
  <div class="wrapper">
    <v-head></v-head>
    <v-sidebar></v-sidebar>
    <div  class="content-box" :class="{'content-collapse':isCollapse}">
      <div class="content">
        <transition name="move" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>

  </div>
</template>

<script>
import vHead from '../components/Header.vue'
import vSidebar from '../components/Sidebar.vue'
import bus from '../api/bus'
export default {
  data(){
    return {
      isCollapse:false
    }
  },

  components:{
    vHead, vSidebar
  },
  created(){
    bus.$on('collapse_sidebar', msg => {
      this.isCollapse = msg
      // this.selectMenu(0)
    })
  },
  methods: {
  }
}
</script>
<style scoped>
  .content-collapse {
    left: 65px;
  }
</style>
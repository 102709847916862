<template>
  <div class="header">
    <div class="logo_wrapper">
      <el-image style="width: 50px; height: 50px; margin-right: 10px" :src="require('../assets/images/logo2.png')"
        fit="fit" @click="collapse = !collapse"></el-image>
      <div class="company_name">
        <span></span>
      </div>
    </div>
    <div class="main_menu">
      <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect" text-color="#212121"
        active-text-color="#3b7Bcf">
        <el-menu-item :index="item.menuId + ''" v-for="item in menuList" :key="item.menuId">
          <div class="main_menu_item">
            <i :class="item.icon + ' iconfont '"></i>
            <span>{{ item.menuName }}</span>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="user_wrapper">
      <div class="user-avator">
        <el-avatar size="medium" :src="require('../assets/images/defhead.jpeg')"></el-avatar>
      </div>
      <el-dropdown class="user-name" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ nickName }} <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item divided command="updatePasswd">修改密码</el-dropdown-item>
          <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 添加菜单弹窗 -->
    <update-passwd @close="closeDialog" v-if="dialogState" ref="addMenu" />
  </div>
</template>

<script>
import bus from "../api/bus";
import { removeToken } from "@/utils/auth";
import updatePasswd from "./updatePassword";
export default {
  data() {
    return {
      collapse: false, // 收起侧边栏
      currentLocation: "",
      dialogState: false,
    };
  },
  components: {
    updatePasswd,
  },
  computed: {
    nickName() {
      return this.$store.state.user.nickName;
    },
    menuList() {
      return this.$store.state.permission.menuList;
    },
    activeIndex() {
      const route = this.$route;
      const { path } = route;
      let id = "";
      this.$store.state.permission.menuList.forEach((i) => {
        if (path.indexOf(i.path) != -1) {
          id = i.menuId;
          this.$store.dispatch("setMenuActive", id);
        }
      });
      return id + "";
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    collapse(nv, ov) {
      bus.$emit("collapse_sidebar", nv);
    },
    menuList: {
      handler(val) {
        const route = this.$route;
        const { path } = route;
        let id = "";
        val.forEach((i) => {
          if (path.indexOf(i.path) != -1) {
            id = i.menuId;
            this.$store.dispatch("setMenuActive", id);
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    closeDialog(e) {
      this.dialogState = false;
      if (e) {
        removeToken();
        this.$router.push("/login");
      }
    },
    handleCommand(command) {
      if (command == "loginout") {
        removeToken();
        this.$router.push("/login");
      } else if (command == "updatePasswd") {
        this.dialogState = true;
      }
    },
    handleSelect(key) {
      this.$store.dispatch("setMenuActive", key);
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-size: 22px;
  color: #212121;
  background: #fff;
  box-shadow: 0px 2px 4px #bdbdbd;
}

.logo_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
}

.company_name {
  font-size: 18px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-name {
  margin-left: 10px;
}

.el-dropdown-link {
  color: #212121;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}

.user_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
}

.main_menu {
  flex: 1;
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.main_menu_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.main_menu_item span {
  margin-left: 10px;
}
</style>